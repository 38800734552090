import { Controller } from "@hotwired/stimulus"

import Stimulus from "../../shared/utils/stimulus"

class ClearingNumberExamplesDropdown extends Controller {
  static targets = [`data`, `entry`]

  connect() {
    this.element
      .querySelector(`select`)
      .addEventListener(`change`, this.toggle.bind(this))
  }

  toggle(evt) {
    this.dataTarget.classList.toggle(`selected`, evt.target.value)

    this.entryTargets.forEach((entry) => {
      entry.classList.toggle(
        `selected`,
        evt.target.value == entry.dataset.clearingNumberExamplesDropdownEntry
      )
    })
  }
}

Stimulus.register(
  `clearing-number-examples-dropdown`,
  ClearingNumberExamplesDropdown
)
