import { Controller } from "@hotwired/stimulus"
import Stimulus from "../../shared/utils/stimulus"

class FaqTable extends Controller {
  static targets = [`hideable`, `toggle`]

  connect() {
    this.showInitialItems()
  }

  showInitialItems() {
    this.hideableTargets.forEach((target, index) => {
      if (index < 10) {
        target.classList.remove(`--hidden`)
      } else {
        target.classList.add(`--hidden`)
      }
    })

    const hasMoreItems = this.hideableTargets.length > 10
    if (!hasMoreItems) {
      this.toggleTarget.classList.add(`--hidden`)
      this.element.querySelector(`.plus-icon`).classList.add(`--hidden`)
    } else {
      this.toggleTarget.innerHTML = `Visa fler rader`
      this.toggleTarget.classList.remove(`--hidden`)
    }
  }

  toggleTable() {
    const isHidden = this.hideableTargets[10].classList.contains(`--hidden`)

    this.hideableTargets.forEach((target, index) => {
      if (isHidden) {
        target.classList.remove(`--hidden`)
        this.element.querySelector(`.minus-icon`).classList.remove(`--hidden`)
        this.element.querySelector(`.plus-icon`).classList.add(`--hidden`)
      } else {
        if (index >= 10) {
          target.classList.add(`--hidden`)
          this.element.querySelector(`.plus-icon`).classList.remove(`--hidden`)
          this.element.querySelector(`.minus-icon`).classList.add(`--hidden`)
        }
      }
    })

    if (this.hasToggleTarget) {
      this.toggleTarget.innerHTML = isHidden ? `Visa mindre` : `Visa fler rader`
    }
  }
}

Stimulus.register(`faq-table`, FaqTable)
