import { Controller } from "@hotwired/stimulus"
import { createPopper } from "@popperjs/core"

import Stimulus from "../../shared/utils/stimulus"

class PiggyBankFeature extends Controller {
  static targets = [`cta`, `popper`, `image`]
  #popperInstances = {}

  connect() {
    this.popperTarget.classList.add(`--active`)

    this.ctaTargets.forEach((cta, i) => {
      this.#popperInstances[cta.dataset.name] = createPopper(
        cta,
        this.popperTargets[i],
        {
          placement: `left`,
          strategy: `fixed`,
          modifiers: [
            {
              name: `flip`,
              options: {
                fallbackPlacements: [`top`, `bottom`],
              },
            },
            {
              name: `offset`,
              options: {
                offset: [20, 20],
              },
            },
          ],
        }
      )
    })

    if (this.hasImageTarget) {
      this.imageTarget.addEventListener(`load`, () =>
        this.#popperInstances[this.popperTarget.dataset.name].forceUpdate()
      )
    }
  }

  togglePopper = (e) => {
    const { name } = e.target.dataset

    this.popperTargets.forEach((item) => {
      // Ignore current clicked
      if (item.dataset.name == name) return

      item.classList.remove(`--active`)
    })

    this.popperTargets
      .find((p) => p.dataset.name == name)
      .classList.toggle(`--active`)

    this.#popperInstances[name].forceUpdate()
  }
}

Stimulus.register(`piggy-bank-feature`, PiggyBankFeature)
