import { Controller } from "@hotwired/stimulus"
import { toggleClass } from "@oddcamp/js-utils/src/attribute"

import Stimulus from "../../shared/utils/stimulus"

class RatesMatrix extends Controller {
  static targets = [`hideable`, `toggle`]

  connect() {
    toggleClass(this.hideableTargets, `--hidden`)
  }

  toggleTable() {
    toggleClass(this.hideableTargets, `--hidden`)

    if (this.hasToggleTarget)
      this.toggleTarget.innerHTML =
        this.toggleTarget.innerHTML === `Visa mer` ? `Visa mindre` : `Visa mer`
  }
}

Stimulus.register(`rates-matrix`, RatesMatrix)
