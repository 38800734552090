import { Controller } from "@hotwired/stimulus"

import Stimulus from "../../shared/utils/stimulus"

class InfoPageContainer extends Controller {
  connect() {
    window.scrollTo({ top: 0, behavior: `instant` })
    window.setTimeout(this.scrollToElement, 500)
    window.addEventListener(`hashchange`, this.scrollToElement, 50)
  }

  disconnect() {
    window.removeEventListener(`hashchange`, this.scrollToElement)
  }

  scrollToElement = () => {
    const hash = window.location.hash
    if (!hash) return

    const element = this.element.querySelector(hash)
    if (!element) return

    const elementPosition =
      element.getBoundingClientRect().top + document.documentElement.scrollTop
    const headerOffset = document.querySelector(`.header`).offsetHeight
    const offsetPosition = elementPosition - headerOffset - 40

    window.scrollTo({
      top: offsetPosition,
      behavior: `smooth`,
    })
  }
}

Stimulus.register(`info-page-container`, InfoPageContainer)
