import { Controller } from "@hotwired/stimulus"
import { hasClosest } from "@oddcamp/js-utils/src/selector"
import { toggleClass, removeClass } from "@oddcamp/js-utils/src/attribute"

import Stimulus from "../../shared/utils/stimulus"

class Header extends Controller {
  static targets = [
    `hamburger`,
    `navigation`,
    `navigationItem`,
    `navigationBar`,
    `hamburgerText`,
  ]
  #currentTarget = {}

  connect() {
    window.addEventListener(`keyup`, this.winKeyup, { passive: true })
    document.addEventListener(`click`, this.docClick, { passive: true })
    document.addEventListener(`turbo:load`, this.turboLoad)
  }

  disconnect() {
    window.removeEventListener(`keyup`, this.winKeyup, { passive: true })
    document.removeEventListener(`click`, this.docClick, { passive: true })
    document.removeEventListener(`turbo:load`, this.turboLoad)
  }

  toggleNavigation() {
    toggleClass(
      [this.hamburgerTarget, this.navigationTarget, this.hamburgerTextTarget],
      `--open`
    )
    toggleClass(document.documentElement, `--header-menu-open`)
  }

  showSubmenu(e) {
    e.preventDefault()

    this.#currentTarget = e.currentTarget

    const itemTarget = e.currentTarget.closest(
      `[data-header-target="navigationItem"]`
    )
    const isOpen = itemTarget.classList.contains(`--open`)

    this.closeNavigationItems()

    if (!isOpen) {
      itemTarget.classList.add(`--open`)
      this.navigationBarTarget.classList.add(`--open`)
    } else {
      this.navigationBarTarget.classList.remove(`--open`)
    }

    this.updateHeaderOpenState()
  }

  docClick = (e) => {
    if (!hasClosest(e.target, this.#currentTarget)) {
      this.closeNavigationItems()
    }
  }

  winKeyup = (e) => {
    if (e.key == `Escape`) this.closeNavigationItems()
  }

  closeNavigationItems = () => {
    removeClass(this.navigationItemTargets, `--open`)
    this.updateHeaderOpenState()
  }

  updateHeaderOpenState() {
    const anyOpen = this.navigationItemTargets.some((item) =>
      item.classList.contains(`--open`)
    )
    if (!anyOpen) {
      this.navigationBarTarget.classList.remove(`--open`)
    } else {
      this.navigationBarTarget.classList.add(`--open`)
    }
  }

  turboLoad = () => {
    document.documentElement.classList.remove(`--header-menu-open`)
  }
}

Stimulus.register(`header`, Header)
