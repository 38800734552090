import { Controller } from "@hotwired/stimulus"

import Stimulus from "../../shared/utils/stimulus"

class Tracker extends Controller {
  static values = { google: String }

  track() {
    if (this.hasGoogleValue && window.gtag)
      window.gtag(`event`, this.googleValue)
  }
}

Stimulus.register(`tracker`, Tracker)
