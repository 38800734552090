import { Controller } from "@hotwired/stimulus"
import { removeClass } from "@oddcamp/js-utils/src/attribute"

import Stimulus from "../../shared/utils/stimulus"

class HeroBanner extends Controller {
  static targets = [`card`, `link`]

  connect() {
    if (this.hasCardTarget)
      window.setTimeout(
        () => removeClass(this.cardTarget, `content-grid__card--hidden`),
        500
      )
  }

  link() {
    if (this.hasLinkTarget) window.location.href = this.linkTarget.href
  }
}

Stimulus.register(`hero-banner`, HeroBanner)
