import { Controller } from "@hotwired/stimulus"
import { PageFlip } from "page-flip"

import Stimulus from "../../shared/utils/stimulus"

class PiggyBook extends Controller {
  static targets = [`pages`, `page`, `navNext`, `navPrev`, `dots`]
  #outerHTML = ``
  #pageFlip = null
  #dots = []

  connect() {
    this.#outerHTML = this.element.outerHTML

    this.#pageFlip = new PageFlip(this.pagesTarget, {
      width: 520,
      height: 640,
      minWidth: 280,
      size: `stretch`,
      showCover: true,
    })

    this.#pageFlip.on(`flip`, this.flipped)
    this.#pageFlip.loadFromHTML(this.pageTargets)
    this.navNextTarget.disabled = false
    this.insertDots()
    this.turnToHashPage()
    window.addEventListener(`popstate`, this.turnToHashPage)
  }

  disconnect() {
    this.#pageFlip.destroy()
    this.element.outerHTML = this.#outerHTML
    window.removeEventListener(`popstate`, this.turnToHashPage)
  }

  turnToPrevPage = () => {
    this.#pageFlip.flipPrev()
  }

  turnToNextPage = () => {
    this.#pageFlip.flipNext()
  }

  flipped = (e) => {
    if (!e.data) {
      this.navPrevTarget.disabled = true
    } else if (e.data + 1 == this.#pageFlip.getPageCount()) {
      this.navNextTarget.disabled = true
    } else {
      this.navPrevTarget.disabled = false
      this.navNextTarget.disabled = false
    }

    this.selectCurrentDot()
  }

  getMethods = (obj) => Object.getOwnPropertyNames(obj)

  dotClick = (e) => {
    const num =
      e.target.dataset.index * 2 -
      (e.target.dataset.index * 2 == this.#pageFlip.getPageCount() ? 1 : 0)

    this.#pageFlip.flip(num)
  }

  insertDots = () => {
    for (let i = 1; i < this.#pageFlip.getPageCount() / 2 + 2; i++) {
      const button = document.createElement(`button`)
      button.innerText = i
      button.title = i
      button.dataset.index = i - 1
      button.addEventListener(`click`, this.dotClick, { passive: true })
      this.dotsTarget.appendChild(button)
      this.#dots.push(button)
    }

    this.selectCurrentDot()
  }

  selectCurrentDot = () => {
    if (!this.#dots.length) return
    this.#dots.forEach((d) => d.classList.remove(`--active`))
    this.#dots[
      Math.floor((this.#pageFlip.getCurrentPageIndex() + 1) / 2)
    ].classList.add(`--active`)
  }

  turnToHashPage = () => {
    const hash = window.location.hash.replace(`#`, ``)
    if (!hash || !this.hasPageTarget) return

    const index = this.pageTargets.findIndex((t) => t.id == hash)
    if (index < 0) return

    this.#pageFlip.flip(index)
    this.element.scrollIntoView(true)
  }
}

Stimulus.register(`piggy-book`, PiggyBook)
