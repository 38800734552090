import { Controller } from "@hotwired/stimulus"
import bodymovin from "lottie-web"
import Stimulus from "../../shared/utils/stimulus"

class Animations extends Controller {
  static targets = [`stage`]
  static values = { loops: Number }

  #animation
  #playCount = 0

  connect() {
    const animationName = this.stageTarget.dataset.animationName
    const loopCount =
      this.stageTarget.dataset.animationsLoopsValue === `true`
        ? true
        : parseInt(this.stageTarget.dataset.animationsLoopsValue, 10)

    this.#animation = bodymovin.loadAnimation({
      container: this.stageTarget,
      renderer: `svg`,
      loop: false,
      autoplay: true,
      path: `/animations/${animationName}.json`,
    })

    if (typeof loopCount === `number`) {
      this.maxPlays = loopCount
      this.playCount = 0
      this.#animation.addEventListener(
        `complete`,
        this.handleComplete.bind(this)
      )
    }
  }

  handleComplete() {
    this.playCount += 1

    if (this.playCount < this.maxPlays) {
      this.#animation.goToAndPlay(0)
    } else {
      this.#animation.removeEventListener(
        `complete`,
        this.handleComplete.bind(this)
      )
    }
  }

  disconnect() {
    this.#animation.destroy()
  }
}

Stimulus.register(`animations`, Animations)
