import { Controller } from "@hotwired/stimulus"
import Swiper, { Keyboard } from "swiper"
import { removeClass } from "@oddcamp/js-utils/src/attribute"

import Stimulus from "../../shared/utils/stimulus"

Swiper.use([Keyboard])

class Gallery extends Controller {
  static targets = [`swiper`, `navPrev`, `navNext`, `dot`]
  #swiper

  connect() {
    this.#swiper = new Swiper(this.swiperTarget, {
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
    })

    this.#swiper.on(`slideChange`, this.slideChange)
    this.slideChange()
  }

  slideChange = () => {
    removeClass(this.dotTargets, `--active`)
    this.dotTargets[this.#swiper.activeIndex].classList.add(`--active`)

    this.navPrevTarget.disabled = !this.#swiper.activeIndex
    this.navNextTarget.disabled =
      this.#swiper.activeIndex == this.#swiper.slides.length - 1
  }

  showPrev = () => {
    this.#swiper.slidePrev()
  }

  showNext = () => {
    this.#swiper.slideNext()
  }

  showSlide = (e) => {
    this.#swiper.slideTo(e.target.dataset.index)
  }
}

Stimulus.register(`gallery`, Gallery)
