import { Controller } from "@hotwired/stimulus"

import Stimulus from "../../shared/utils/stimulus"

class DisclaimerPageContent extends Controller {
  static targets = [`disclaimer`, `rejection`]

  reject() {
    this.disclaimerTarget.classList.add(`hidden`)
    this.rejectionTarget.classList.remove(`hidden`)
  }
}

Stimulus.register(`disclaimer-page-content`, DisclaimerPageContent)
